import React from "react"
import { Container } from "react-bootstrap";

export default function Footer() {
  return (
    <footer className="border-top footer text-muted">
      <Container>
        &copy; 2024 - G&C
      </Container>
    </footer>
  )
}