import React, { useEffect, useState } from "react"

export function CookTimeBanner() {
  return (
  <section className="py-5 text-center container margin-bottom-10">
    <div className="row py-lg-5">
      <h1 className="fw-light">The CookTime Experience</h1>
      <p className="lead text-muted">CookTime is the recipe organizer you have been waiting for.</p>
    </div>
  </section>);
}