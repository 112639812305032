import logo from "./CookTime-Logo.png";
import placeholder from "./placeholder.jpg"
import beans from "./beans.png"
import berries from "./berries.png"
import cruciferous from "./cruciferous.png"
import flaxseed from "./flaxseed.png"
import greens from "./greens.png"
import nuts from "./nuts.png"
import otherFruit from "./other-fruit.png"
import otherVegetable from "./other-vegetables.png"
import spices from "./spices.png"
import wholeGrains from "./whole-grains.png"

const imgs = {
  logo,
  placeholder,
  beans,
  berries,
  cruciferous,
  flaxseed,
  greens,
  nuts,
  otherFruit,
  otherVegetable,
  spices,
  wholeGrains,
}

export default imgs;