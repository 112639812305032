export function GoogleInFeedAds() {
  return (
    <>
      <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6004231239349931"
        crossOrigin="anonymous"></script>
      <ins className="adsbygoogle"
        style={{"display":"block", "textAlign":"center"}}
        data-ad-layout="in-article"
        data-ad-format="fluid"
        data-ad-client="ca-pub-6004231239349931"
        data-ad-slot="5207463487"></ins>
      <script>
        (adsbygoogle = window.adsbygoogle || []).push({ });
      </script>
    </>)
}